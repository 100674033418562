<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          {{ categoria_id ? "Editar Categoria" : "Adicionar Categoria" }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>

          <v-row dense class="px-4">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.descricao"
                label="Descrição"
                :rules="formRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/loja/categorias' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="onSubmit"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon v-if="!categoria_id" left>mdi-plus</v-icon>
            {{ categoria_id ? "Atualizar" : "Adicionar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { inputRequired } from "@/plugins/utils.js";
import {
  createCategoria,
  getCategoria,
  updateCategoria,
} from "@/api/admin/loja";

export default {
  name: "NovaCategoria",

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      form: {
        descricao: "",
        status: false,
      },
      categoria: null,
    };
  },

  props: ["categoriaId"],

  watch: {},

  computed: {
    categoria_id() {
      return this.$route.params?.categoriaId;
    },
  },

  methods: {
    createCategoria() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        createCategoria(this.form.descricao)
          .then(() => {
            this.$toast.success("Criado com sucesso");
            this.loading = false;
            this.$router.push({ path: "/loja/categorias" });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    updateCategoria() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.categoria.descricao = this.form.descricao;
        this.categoria.status = Number(this.form.status);

        updateCategoria(this.categoria)
          .then(() => {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
            this.$router.push({ path: "/loja/categorias" });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getCategoria() {
      getCategoria(this.$route.params?.categoriaId)
        .then((response) => {
          this.categoria = response;
          this.form.descricao = response.descricao;
          this.form.status = response.status;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    onSubmit() {
      if (this.categoria_id) this.updateCategoria();
      else this.createCategoria();
    },
  },

  async mounted() {
    this.loading = true;
    if (this.$route.params?.categoriaId) await this.getCategoria();
    this.loading = false;
  },
};
</script>

<style></style>
